import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index'
import {auth} from "../firebase"
import {signInWithEmailAndPassword, sendPasswordResetEmail, signOut} from "firebase/auth"
import {db} from '../firebase'
import {ref, onValue, update} from "firebase/database";
//import { collection, query, where, onSnapshot, Timestamp, orderBy, doc } from "firebase/firestore"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    events: [],
    user: null,
    error: null,
    //heartbeat: null,
    environment: null,
    machineStopsActive: 0
  },
  mutations: {
    SET_USER(state, payload){
      state.user=payload
    },
    SET_ERROR(state, payload){
      state.error=payload
    },
    SET_EVENTS(state, payload){
      state.events=payload
    },
    CLEAR_EVENTS(state, payload){
      state.events=payload
    },
    /* SET_HB(state, payload){
      state.heartbeat.push(payload)
    },
    CLEAR_HB(state, payload){
      state.heartbeat=payload
    }, */
    SET_ENVIRONMENT(state, payload){
      state.environment=payload
    },
    SET_MACHINESTOPSACTIVE(state, payload){
      state.machineStopsActive=payload
    }
  },
  actions: {
    async logIn({commit}, userLogin){
      try {
        const userSnapshot = await signInWithEmailAndPassword(auth, userLogin.email, userLogin.password)
        const {accessToken,email,emailVerified} = userSnapshot.user
        const user = {accessToken,email,emailVerified}
        commit('SET_USER', user)
        commit('SET_ERROR', null)
        router.push('/environment')
        sessionStorage.setItem('user', JSON.stringify(user))
      } catch (error) {
          let message=null
          if(error.code==='auth/user-not-found'){message='Usuario no encontrado'}
          if(error.code==='auth/wrong-password'){message='Contraseña incorrecta'}
          commit('SET_ERROR', message)
        }
    },

    async logOut({commit}){
      try {
        await signOut(auth)
        commit('SET_USER', null)
        commit('CLEAR_EVENTS', [])
        sessionStorage.removeItem('user')
        router.push('/login')
      } catch (error) {
          console.log('Code: '+error.code)
          console.log('Msg: '+error.message)
      }
    },

    async resetPassword({commit}, email){
      try {
        await sendPasswordResetEmail(auth, email)
        alert("Se ha enviado los pasos a seguir a tu correo para reiniciar la contraseña")
      } catch (error) {
        console.log('Code: '+error.code)
        console.log('Msg: '+error.message)
      }
    },

    detectUser({commit}, user){
      commit('SET_USER', user)
    },

    getEvents({commit}){
      const stopsRef = ref(db, 'stops')
      onValue(stopsRef, snapshot => {
        //console.log(snapshot.val())
        commit('SET_EVENTS', Object.values(snapshot.val()))
        //commit('SET_EVENTS', snapshot.val())
      })
    },

    getEnvironment({commit}){
      const envRef = ref(db, 'environment')
      onValue(envRef, snapshot => {
        //console.log(snapshot.val())
        //commit('SET_ENVIRONMENT', Object.values(snapshot.val()))
        commit('SET_ENVIRONMENT', snapshot.val())
      })
    },

    getMachineStopsActive({commit}){
      const dataRef = ref(db, 'configuration/machineStops/active')
      onValue(dataRef, snapshot => {
        //console.log(snapshot.val())
        commit('SET_MACHINESTOPSACTIVE', snapshot.val())
      })
    }

    /* getHeartbeat({commit, state}){
      try {
        const q = query(collection(db, "status"))
        const unsubscribe = onSnapshot(q, querySnapshot => {
          commit('CLEAR_HB', [])
          querySnapshot.forEach(doc =>{
            commit('SET_HB', doc.data())
          })
        })
      } catch (error) {
        console.log(error)
      }
    }, */

    
    

  },

  getters: {
    userIsAuth(state){
      if(state.user){return true}
      else{return false}
    },
    

  },
  modules: {
  }
})
