import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import {auth} from './firebase'
import {onAuthStateChanged} from "firebase/auth"

Vue.config.productionTip = false

onAuthStateChanged(auth, (user) => {
  if(user && sessionStorage.getItem('user')){ 
    store.dispatch('detectUser', JSON.parse(sessionStorage.getItem('user')))
  }else {
    store.dispatch('detectUser', user)
  }   

}) 



new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
