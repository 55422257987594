<template>
  <v-app>
    <v-app-bar
      app
      elevation="6"
      src="./assets/background.jpg"
    >
      <div class="d-flex align-center">
        <v-app-bar-nav-icon v-if="userIsAuth" @click.stop="drawer = true"></v-app-bar-nav-icon>
        
      </div>
      
      <!-- <div class="d-flex align-center">
        <v-img
          alt="Gemetytec"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="20"
          src="./assets/gemetytec.png"
          width="120"
        />
      </div> -->

      <v-spacer></v-spacer>

     <v-btn
        href="https://anukin.com"
        target="_blank"
        text
      >
        <span class="mr-2 mt-3" style="text-transform:none;color:gray;">Powered by</span>
        <v-img max-width="90" contain src="./assets/anukin.png"></v-img>
      </v-btn>
      
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" absolute temporary dark>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">Menu</v-list-item-title>
          <!--<v-list-item-subtitle>subtext</v-list-item-subtitle>-->
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item
            v-for="item in items"
            :key="item.title"
            link
            :to="item.link" 
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link @click.prevent="resetPassword(user.email)">
          <v-list-item-icon>
            <v-icon>mdi-form-textbox-password</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Reiniciar Contraseña</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click.prevent="logOut">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Salir</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>


    <v-main class="customBackground">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'App',

  data: () => ({
      items: [
      { title: 'Monitoreo Ambiental', icon: 'mdi-sprout', link: '/environment' },
      { title: 'Rastreo de activos', icon: 'mdi-broadcast', link: '/mesh' },
      { title: 'Paros Máquina', icon: 'mdi-state-machine', link: '/' },  
      { title: 'Configuración', icon: 'mdi-cog', link: '/configuration' }, 
      ],
      right: null,
      drawer: false,
      group: null
  }),

  computed: {
    ...mapGetters(['userIsAuth']),
    ...mapState(['user', 'error'])
  },

  methods: {
    ...mapActions(['logOut','resetPassword'])
  },
};
</script>

<style>
.customBackground{
  background: url('./assets/background.jpg');
  background-size: cover;
}
</style>
