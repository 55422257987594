import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

// DEV Configuration
const firebaseConfig = {
  apiKey: "AIzaSyBN3--6BVtOdHH8XKUmwDyATirerNyARHs",
  authDomain: "anukin-solutionspoc.firebaseapp.com",
  databaseURL: "https://anukin-solutionspoc-default-rtdb.firebaseio.com",
  projectId: "anukin-solutionspoc",
  storageBucket: "anukin-solutionspoc.appspot.com",
  messagingSenderId: "576829669320",
  appId: "1:576829669320:web:fbad02501620da0b438afd"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getDatabase(app);
const auth = getAuth(app);

export {db,auth}